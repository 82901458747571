// This object contains all string id used for i18n translation.
// We use an object to wrap all string id so we can reduce typos like "outbond" when we want to reference a string id in code.
const keys = {
  ACTIONS: "ACTIONS",
  ACTIVE: "ACTIVE",
  ADD: "ADD",
  ADD_BUILDING: "ADD_BUILDING",
  ADD_DEVICE: "ADD_DEVICE",
  ADD_DEVICE_ERROR_MESSAGE: "ADD_DEVICE_ERROR_MESSAGE",
  ADD_GATE: "ADD_GATE",
  ADD_LANE: "ADD_LANE",
  ADD_OFFSITE_YARD: "ADD_OFFSITE_YARD",
  ADD_LOCATION: "ADD_LOCATION",
  ADD_SUBSCRIPTION: "ADD_SUBSCRIPTION",
  ADD_YARD: "ADD_YARD",
  ADD_YARD_ACTIONS: "ADD_YARD_ACTIONS",
  ADD_YARD_ENTITY_ACTIONS: "ADD_YARD_ENTITY_ACTIONS",
  AIRPLANE: "AIRPLANE",
  ALL_3P_YARDS_ADDED: "ALL_3P_YARDS_ADDED",
  AMAZON: "AMAZON",
  AMAZON_TOM_MANAGED: "AMAZON_TOM_MANAGED",
  ANNOTATION_INVALID_ANNOTATION_WARNING_TITLE: "ANNOTATION_INVALID_ANNOTATION_WARNING_TITLE",
  ANNOTATION_MISSING_LANE_SEGMENTATION_WARNING_PREFIX:
    "ANNOTATION_MISSING_LANE_SEGMENTATION_WARNING_PREFIX",
  ANNOTATION_PANEL_TITLE_PREFIX: "ANNOTATION_PANEL_TITLE_PREFIX",
  ANNOTATION_UNCLOSED_SEGMENTATION_WARNING: "ANNOTATION_UNCLOSED_SEGMENTATION_WARNING",
  ANNOTATION_UNLABELED_SEGMENTATION_WARNING: "ANNOTATION_UNLABELED_SEGMENTATION_WARNING",
  ANNOTATION_UPLOAD_FAILED_MESSAGE_PREFIX: "ANNOTATION_UPLOAD_FAILED_MESSAGE_PREFIX",
  ANNOTATION_UPLOAD_FAILED_TITLE: "ANNOTATION_UPLOAD_FAILED_TITLE",
  ANNOTATION_UPLOAD_COMPLETED_MESSAGE: "ANNOTATION_UPLOAD_COMPLETED_MESSAGE",
  ANNOTATION_UPLOAD_COMPLETED_TITLE: "ANNOTATION_UPLOAD_COMPLETED_TITLE",
  ASSET_TYPE: "ASSET_TYPE",
  ASSET_TYPES: "ASSET_TYPES",
  AUDIT_MODE_SEARCH: "AUDIT_MODE_SEARCH",
  BATCH_ACTIONS: "BATCH_ACTIONS",
  BATCH_ADD_3P_YARDS: "BATCH_ADD_3P_YARDS",
  BOX_SPRINTER_VAN: "BOX_SPRINTER_VAN",
  BOX_TRUCK: "BOX_TRUCK",
  BOX_TRUCK_10T: "BOX_TRUCK_10T",
  BOX_TRUCK_2T: "BOX_TRUCK_2T",
  BOX_TRUCK_4T: "BOX_TRUCK_4T",
  BUILDING: "BUILDING",
  BUILDINGS: "BUILDINGS",
  BUILDING_ACTIONS: "BUILDING_ACTIONS",
  BUILDING_CODE: "BUILDING_CODE",
  BUILDING_CODE_AVAILABLE: "BUILDING_CODE_AVAILABLE",
  BUILDING_CODE_USED: "BUILDING_CODE_USED",
  BUILDING_DESCRIPTION: "BUILDING_DESCRIPTION",
  CAMERA_MASKS: "CAMERA_MASKS",
  CANCEL: "CANCEL",
  CAPACITY_MULTI_CAPACITY_MISMATCH_ERROR_MESSAGE: "CAPACITY_MULTI_CAPACITY_MISMATCH_ERROR_MESSAGE",
  CAPTURE_IMAGE: "CAPTURE_IMAGE",
  CAR: "CAR",
  CHANGED: "CHANGED",
  CHANGING_AUDIT_ORDER: "CHANGING_AUDIT_ORDER",
  CHANGING_SCREEN_ORDER: "CHANGING_SCREEN_ORDER",
  CHECK_BUILDING_CODE_AVAILABILITY_ERROR_MESSAGE: "CHECK_BUILDING_CODE_AVAILABILITY_ERROR_MESSAGE",
  CHECK_YARD_LABEL_AVAILABILITY_ERROR_MESSAGE: "CHECK_YARD_LABEL_AVAILABILITY_ERROR_MESSAGE",
  CHIME: "CHIME",
  CLOSE: "CLOSE",
  CONFIG: "CONFIG",
  CONFIRM_ORDER: "CONFIRM_ORDER",
  CONFIRM_REMAPPING: "CONFIRM_REMAPPING",
  CREATE_BUILDING_ERROR_MESSAGE: "CREATE_BUILDING_ERROR_MESSAGE",
  CREATE_OFFSITE_YARD_ERROR_MESSAGE: "CREATE_OFFSITE_YARD_ERROR_MESSAGE",
  CREATE_YARD_ERROR_MESSAGE: "CREATE_YARD_ERROR_MESSAGE",
  CSV_PARSE_FAILURE: "CSV_PARSE_FAILURE",
  CSV_UPLOAD_FAILURE: "CSV_UPLOAD_FAILURE",
  CSV_YARD_LABELS_BUILDING_ALREADY_EXISTS: "CSV_YARD_LABELS_BUILDING_ALREADY_EXISTS",
  CSV_YARD_LABELS_DUPLICATE: "CSV_YARD_LABELS_DUPLICATE",
  CSV_YARD_LABELS_INVALID: "CSV_YARD_LABELS_INVALID",
  CSV_YARD_LABELS_LIMIT_EXCEEDED: "CSV_YARD_LABELS_LIMIT_EXCEEDED",
  CSV_YARD_LABELS_NONE_FOUND: "CSV_YARD_LABELS_NONE_FOUND",
  CSV_YARD_LABELS_WRONG_HEADER: "CSV_YARD_LABELS_WRONG_HEADER",
  CSV_YARD_LABELS_YARD_ALREADY_EXISTS: "CSV_YARD_LABELS_YARD_ALREADY_EXISTS",
  DELETE_LOCATION_MAPPING: "DELETE_LOCATION_MAPPING",
  DESTINATION_YARD: "DESTINATION_YARD",
  DEVICES: "DEVICES",
  DEVICE_TO_MOVE: "DEVICE_TO_MOVE",
  DEVICE_TO_REMOVE: "DEVICE_TO_REMOVE",
  DISABLED: "DISABLED",
  DISABLE: "DISABLE",
  DISPLAY_AND_OVERLAY_MASK: "DISPLAY_AND_OVERLAY_MASK",
  DOCUMENTS: "DOCUMENTS",
  DOCUMENT_TYPE: "DOCUMENT_TYPE",
  DOLLY: "DOLLY",
  DOWNLOAD: "DOWNLOAD",
  DOWNLOAD_CSV: "DOWNLOAD_CSV",
  DOWNLOAD_CSV_AND_CLOSE: "DOWNLOAD_CSV_AND_CLOSE",
  DOWNLOAD_CSV_TEMPLATE: "DOWNLOAD_CSV_TEMPLATE",
  DOWNLOAD_FILE: "DOWNLOAD_FILE",
  EDIT: "EDIT",
  EDIT_BUILDING: "EDIT_BUILDING",
  EDIT_BUILDING_ERROR_MESSAGE: "EDIT_BUILDING_ERROR_MESSAGE",
  EDIT_GATE: "EDIT_GATE",
  EDIT_LANE: "EDIT_LANE",
  EDIT_LOCATION: "EDIT_LOCATION",
  EDIT_SUBSCRIPTION: "EDIT_SUBSCRIPTION",
  EDIT_YARD: "EDIT_YARD",
  EDIT_YARD_ERROR_MESSAGE: "EDIT_YARD_ERROR_MESSAGE",
  EDIT_YARD_MAP_ERROR_MESSAGE: "EDIT_YARD_MAP_ERROR_MESSAGE",
  ENABLED: "ENABLED",
  ENABLE: "ENABLE",
  ENDPOINT_INVALID_ERROR_MESSAGE: "ENDPOINT_INVALID_ERROR_MESSAGE",
  ENTER_YARD_LABEL: "ENTER_YARD_LABEL",
  ENTRY_GATE: "ENTRY_GATE",
  EXIT_GATE: "EXIT_GATE",
  FETCHING_YARD_MAP: "FETCHING_YARD_MAP",
  FETCH_BUILDING_CODES_ERROR_MESSAGE: "FETCH_BUILDING_CODES_ERROR_MESSAGE",
  FETCH_YARD_CONFIG_ERROR_MESSAGE: "FETCH_YARD_CONFIG_ERROR_MESSAGE",
  FETCH_YARD_DEVICES_ERROR_MESSAGE: "FETCH_YARD_DEVICES_ERROR_MESSAGE",
  FETCH_YARD_FEATURES_ERROR_MESSAGE: "FETCH_YARD_FEATURES_ERROR_MESSAGE",
  FETCH_BUILDING_FEATURES_ERROR_MESSAGE: "FETCH_BUILDING_FEATURES_ERROR_MESSAGE",
  FETCH_YARD_MAP_ERROR_MESSAGE: "FETCH_YARD_MAP_ERROR_MESSAGE",
  FILE_EXTENSION_NOT_SUPPORTED_MESSAGE: "FILE_EXTENSION_NOT_SUPPORTED_MESSAGE",
  FILE_EXTENSION_NOT_SUPPORTED_MESSAGE_YARD_DOCUMENT:
    "FILE_EXTENSION_NOT_SUPPORTED_MESSAGE_YARD_DOCUMENT",
  FILE_UPLOADED: "FILE_UPLOADED",
  FILE_UPLOAD_FAILURE_MESSAGE: "FILE_UPLOAD_FAILURE_MESSAGE",
  FILTER_BY_LABEL: "FILTER_BY_LABEL",
  GAS_MASK_NOT_FOUND: "GAS_MASK_NOT_FOUND",
  GATES: "GATES",
  GATES_LOWER_CASE: "GATES_LOWER_CASE",
  GATE_KFB_ACCESS_POINT_ID: "GATE_KFB_ACCESS_POINT_ID",
  GATE_LABEL: "GATE_LABEL",
  GATE_LANE_IDENTIFIER: "GATE_LANE_IDENTIFIER",
  GATE_R4G_MODE: "GATE_R4G_MODE",
  GATE_TYPE: "GATE_TYPE",
  GATE_TYPE_USED: "GATE_TYPE_USED",
  GUARD_SHACK: "GUARD_SHACK",
  HELP: "HELP",
  HOME: "HOME",
  HOSTLER: "HOSTLER",
  IB_EXPRESS: "IB_EXPRESS",
  IMAGE_CAPTURED: "IMAGE_CAPTURED",
  IMAGE_CAPTURED_NOTE: "IMAGE_CAPTURED_NOTE",
  IMEI: "IMEI",
  INACTIVE: "INACTIVE",
  INBOUND_ARRIVAL: "INBOUND_ARRIVAL",
  INTERCOM_PHONE_NUMBER: "INTERCOM_PHONE_NUMBER",
  INTERCOM_PHONE_NUMBER_INVALID: "INTERCOM_PHONE_NUMBER_INVALID",
  KFB_ACCESS_POINT_ID_INVALID: "KFB_ACCESS_POINT_ID_INVALID",
  LABEL_AVAILABLE: "LABEL_AVAILABLE",
  LABEL_USED: "LABEL_USED",
  LANE: "LANE",
  LANE_IDENTIFIER_USED: "LANE_IDENTIFIER_USED",
  LANE_TO_THE_LEFT: "LANE_TO_THE_LEFT",
  LANE_TO_THE_RIGHT: "LANE_TO_THE_RIGHT",
  LANES_LOWER_CASE: "LANES_LOWER_CASE",
  LANGUAGE: "LANGUAGE",
  LANGUAGES: "LANGUAGES",
  LINKS: "LINKS",
  LOADING: "LOADING",
  LOCAL: "LOCAL",
  LOCALE: "LOCALE",
  LOCATIONS: "LOCATIONS",
  LOCATIONS_LOWER_CASE: "LOCATIONS_LOWER_CASE",
  LOCATIONS_TO_MOVE: "LOCATIONS_TO_MOVE",
  LOCATION_AUDIT_ORDER: "LOCATION_AUDIT_ORDER",
  LOCATION_CAPACITY: "LOCATION_CAPACITY",
  LOCATION_CODE: "LOCATION_CODE",
  LOCATION_DESCRIPTION: "LOCATION_DESCRIPTION",
  LOCATION_DETAILS: "LOCATION_DETAILS",
  LOCATION_DOCK_DOOR: "LOCATION_DOCK_DOOR",
  LOCATION_FILE_INVALID_HEADER: "LOCATION_FILE_INVALID_HEADER",
  LOCATION_ID: "LOCATION_ID",
  LOCATION_INBOUND: "LOCATION_INBOUND",
  LOCATION_LABEL: "LOCATION_LABEL",
  LOCATION_MAPPING_COUNT_TEXT: "LOCATION_MAPPING_COUNT_TEXT",
  LOCATION_MULTI_CAPACITY: "LOCATION_MULTI_CAPACITY",
  LOCATION_NON_INVENTORY: "LOCATION_NON_INVENTORY",
  LOCATION_OFFSITE: "LOCATION_OFFSITE",
  LOCATION_OFFSITE_BUILDING_CODE: "LOCATION_OFFSITE_BUILDING_CODE",
  LOCATION_OUTBOUND: "LOCATION_OUTBOUND",
  LOCATION_SCREEN_ORDER: "LOCATION_SCREEN_ORDER",
  LOCATION_SORTABLE: "LOCATION_SORTABLE",
  LOCATION_TYPE: "LOCATION_TYPE",
  LOCATION_ZONE: "LOCATION_ZONE",
  LOCATION_ZONES: "LOCATION_ZONES",
  MANAGE_LOCALES: "MANAGE_LOCALES",
  MAP: "MAP",
  MASK_NOT_FOUND: "MASK_NOT_FOUND",
  MOTORCYCLE: "MOTORCYCLE",
  MOVE: "MOVE",
  MOVE_DEVICE: "MOVE_DEVICE",
  MOVE_DEVICE_ERROR_MESSAGE: "MOVE_DEVICE_ERROR_MESSAGE",
  MOVE_DOWN: "MOVE_DOWN",
  MOVE_LOCATIONS: "MOVE_LOCATIONS",
  MOVE_LOCATIONS_ERROR_MESSAGE: "MOVE_LOCATIONS_ERROR_MESSAGE",
  MOVE_UP: "MOVE_UP",
  NETWORK: "NETWORK",
  NEW: "NEW",
  NEW_GATE: "NEW_GATE",
  NEW_GUARD_SHACK: "NEW_GUARD_SHACK",
  NEW_LANE: "NEW_LANE",
  NEW_LOCATION: "NEW_LOCATION",
  NEW_SUBSCRIPTION: "NEW_SUBSCRIPTION",
  NO: "NO",
  NON_INVENTORY_ARRIVAL: "NON_INVENTORY_ARRIVAL",
  NOT_FOUND: "NOT_FOUND",
  NO_CAMERAS_FOUND: "NO_CAMERAS_FOUND",
  NO_LANES_FOUND: "NO_LANES_FOUND",
  NO_PERMISSION_ERROR_MESSAGE: "NO_PERMISSION_ERROR_MESSAGE",
  YARD_TYPE_NS: "YARD_TYPE_NS",
  OB_EXPRESS: "OB_EXPRESS",
  OFFSITE: "OFFSITE",
  OFFSITE_BUILDING_CODE_INVALID: "OFFSITE_BUILDING_CODE_INVALID",
  OFFSITE_BUILDING_CODE_VALID: "OFFSITE_BUILDING_CODE_VALID",
  OFFSITE_BUILDING_CODE_CHANGE_WARNING: "OFFSITE_BUILDING_CODE_CHANGE_WARNING",
  OFFSITE_YARDS: "OFFSITE_YARDS",
  OUTBOUND_ARRIVAL: "OUTBOUND_ARRIVAL",
  PERSON: "PERSON",
  PROCEED_TO_ANNOTATION: "PROCEED_TO_ANNOTATION",
  PROPERTIES: "PROPERTIES",
  RECORD_DRIVER: "RECORD_DRIVER",
  REFRESH: "REFRESH",
  RELOAD_CAMERA: "RELOAD_CAMERA",
  REMOVE: "REMOVE",
  REMOVED_LOCATIONS_LOWER_CASE: "REMOVED_LOCATIONS_LOWER_CASE",
  REMOVE_BUILDING: "REMOVE_BUILDING",
  REMOVE_BUILDING_INSTRUCTION: "REMOVE_BUILDING_INSTRUCTION",
  REMOVE_BUILDING_INSTRUCTION_NEW: "REMOVE_BUILDING_INSTRUCTION_NEW",
  REMOVE_BUILDING_SELF_INSTRUCTION_ONE_PREFIX: "REMOVE_BUILDING_SELF_INSTRUCTION_ONE_PREFIX",
  REMOVE_BUILDING_SELF_INSTRUCTION: "REMOVE_BUILDING_SELF_INSTRUCTION",
  REMOVE_BUILDING_SELF_INSTRUCTION_DEVICES: "REMOVE_BUILDING_SELF_INSTRUCTION_DEVICES",
  REMOVE_BUILDING_SELF_INSTRUCTION_EQUIPMENT: "REMOVE_BUILDING_SELF_INSTRUCTION_EQUIPMENT",
  REMOVE_BUILDING_SELF_INSTRUCTION_APPOINTMENTS: "REMOVE_BUILDING_SELF_INSTRUCTION_APPOINTMENTS",
  REMOVE_BUILDING_SELF_INSTRUCTION_RECREATE: "REMOVE_BUILDING_SELF_INSTRUCTION_RECREATE",
  REMOVE_BUILDING_VERIFICATION: "REMOVE_BUILDING_VERIFICATION",
  REMOVE_BUILDING_SUCCESS_MESSAGE: "REMOVE_BUILDING_SUCCESS_MESSAGE",
  REMOVE_BUILDING_ERROR_MESSAGE: "REMOVE_BUILDING_ERROR_MESSAGE",
  REMOVE_DEVICE: "REMOVE_DEVICE",
  REMOVE_DEVICE_ERROR_MESSAGE: "REMOVE_DEVICE_ERROR_MESSAGE",
  REMOVE_DEVICE_WARNING: "REMOVE_DEVICE_WARNING",
  REMOVE_YARD: "REMOVE_YARD",
  REMOVE_YARD_INSTRUCTION: "REMOVE_YARD_INSTRUCTION",
  REMOVE_YARD_INSTRUCTION_NEW: "REMOVE_YARD_INSTRUCTION_NEW",
  REMOVE_YARD_SELF_INSTRUCTION: "REMOVE_YARD_SELF_INSTRUCTION",
  REMOVE_YARD_SELF_INSTRUCTION_BUILDINGS: "REMOVE_YARD_SELF_INSTRUCTION_BUILDINGS",
  REMOVE_YARD_SELF_INSTRUCTION_DEVICES: "REMOVE_YARD_SELF_INSTRUCTION_DEVICES",
  REMOVE_YARD_SELF_INSTRUCTION_EQUIPMENT: "REMOVE_YARD_SELF_INSTRUCTION_EQUIPMENT",
  REMOVE_YARD_SELF_INSTRUCTION_OFFSITES: "REMOVE_YARD_SELF_INSTRUCTION_OFFSITES",
  REMOVE_YARD_VERIFICATION: "REMOVE_YARD_VERIFICATION",
  REMOVE_YARD_SUCCESS_MESSAGE: "REMOVE_YARD_SUCCESS_MESSAGE",
  REMOVE_YARD_ERROR_MESSAGE: "REMOVE_YARD_ERROR_MESSAGE",
  REQUEST_CHANGE: "REQUEST_CHANGE",
  REQUEST_PERMISSION: "REQUEST_PERMISSION",
  REQUEST_REMAPPING: "REQUEST_REMAPPING",
  REQUIRED_ERROR_MESSAGE: "REQUIRED_ERROR_MESSAGE",
  RESTORE: "RESTORE",
  RESULTS: "RESULTS",
  SANDBOX: "SANDBOX",
  SAVE: "SAVE",
  SAVE_CHANGES_TEXT: "SAVE_CHANGES_TEXT",
  SAVE_GATES: "SAVE_GATES",
  SAVE_GATES_ERROR_MESSAGE: "SAVE_GATES_ERROR_MESSAGE",
  SAVE_LOCALES_ERROR_MESSAGE: "SAVE_LOCALES_ERROR_MESSAGE",
  SAVE_LOCATIONS: "SAVE_LOCATIONS",
  SAVE_LOCATIONS_ERROR_MESSAGE: "SAVE_LOCATIONS_ERROR_MESSAGE",
  SAVE_LOCATION_DETAILS: "SAVE_LOCATION_DETAILS",
  SAVE_SUBSCRIPTIONS: "SAVE_SUBSCRIPTIONS",
  SAVE_SUBSCRIPTIONS_ERROR_MESSAGE: "SAVE_SUBSCRIPTIONS_ERROR_MESSAGE",
  SEARCH_FOR_BUILDING: "SEARCH_FOR_BUILDING",
  SEARCH_FOR_YARD: "SEARCH_FOR_YARD",
  SEARCH_FOR_YARD_OR_BUILDING: "SEARCH_FOR_YARD_OR_BUILDING",
  SEE_ALL_RESULTS: "SEE_ALL_RESULTS",
  SELECTED: "SELECTED",
  SELECT_A_CAMERA: "SELECT_A_CAMERA",
  SELECT_ALL: "SELECT_ALL",
  SELECT_ASSET_TYPE: "SELECT_ASSET_TYPE",
  SELECT_DESTINATION_YARD: "SELECT_DESTINATION_YARD",
  SELECT_GATE_TYPE: "SELECT_GATE_TYPE",
  SELECT_LANGUAGE: "SELECT_LANGUAGE",
  SELECT_LANE: "SELECT_LANE",
  SELECT_LOCALE: "SELECT_LOCALE",
  SELECT_LOCATIONS_TO_MOVE: "SELECT_LOCATIONS_TO_MOVE",
  SELECT_LOCATION_INSTRUCTION: "SELECT_LOCATION_INSTRUCTION",
  SELECT_R4G_MODE: "SELECT_R4G_MODE",
  SELECT_SUBSCRIPTION_MESSAGE_TYPE: "SELECT_SUBSCRIPTION_MESSAGE_TYPE",
  SELECT_YARD_FEATURE: "SELECT_YARD_FEATURE",
  SELECT_BUILDING_FEATURE: "SELECT_BUILDING_FEATURE",
  SELECT_YARD_TYPE: "SELECT_YARD_TYPE",
  SHOWING_AUDIT_ORDER: "SHOWING_AUDIT_ORDER",
  SHOWING_SCREEN_ORDER: "SHOWING_SCREEN_ORDER",
  SHOW_AUDIT_ORDER: "SHOW_AUDIT_ORDER",
  SHOW_SCREEN_ORDER: "SHOW_SCREEN_ORDER",
  SLACK: "SLACK",
  SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
  SPRINTER_VAN: "SPRINTER_VAN",
  SSP_IB: "SSP_IB",
  SSP_OB: "SSP_OB",
  STATUS: "STATUS",
  STILL_FETCHING_PERMISSIONS_MESSAGE: "STILL_FETCHING_PERMISSIONS_MESSAGE",
  STILL_FETCHING_FEATURE_FLAGS_MESSAGE: "STILL_FETCHING_FEATURE_FLAGS_MESSAGE",
  SUBMIT: "SUBMIT",
  SUBSCRIPTION_ENDPOINT: "SUBSCRIPTION_ENDPOINT",
  SUBSCRIPTION_MESSAGE_TYPE: "SUBSCRIPTION_MESSAGE_TYPE",
  SUBSCRIPTION_PROTOCOL: "SUBSCRIPTION_PROTOCOL",
  SUBSCRIPTION_STATUS: "SUBSCRIPTION_STATUS",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  SUBSCRIPTIONS_LOWER_CASE: "SUBSCRIPTIONS_LOWER_CASE",
  SUBSCRIPTION_USED_ERROR_MESSAGE: "SUBSCRIPTION_USED_ERROR_MESSAGE",
  SWAP_BODY: "SWAP_BODY",
  TABLE_VIEW: "TABLE_VIEW",
  THIRD_PARTY: "THIRD_PARTY",
  THIRD_PARTY_YARDS_ERRORS_AFTER_ADD_ATTEMPT: "THIRD_PARTY_YARDS_ERRORS_AFTER_ADD_ATTEMPT",
  THIRD_PARTY_YARDS_ERRORS_BEFORE_ADD_ATTEMPT: "THIRD_PARTY_YARDS_ERRORS_BEFORE_ADD_ATTEMPT",
  THIRD_PARTY_YARDS_READY_TO_ADD: "THIRD_PARTY_YARDS_READY_TO_ADD",
  THIRD_PARTY_YARD_ADDED: "THIRD_PARTY_YARD_ADDED",
  THREE_WHEELER: "THREE_WHEELER",
  TRACTOR: "TRACTOR",
  TRACTOR_FLATBED: "TRACTOR_FLATBED",
  TRAILER: "TRAILER",
  TRAILER_BOX_TRUCK: "TRAILER_BOX_TRUCK",
  TRAILER_DOUBLE: "TRAILER_DOUBLE",
  TRAILER_FLATBED: "TRAILER_FLATBED",
  TRAILER_INTERMODAL: "TRAILER_INTERMODAL",
  TRAILER_PUP: "TRAILER_PUP",
  TRAILER_PUP_INTERMODAL: "TRAILER_PUP_INTERMODAL",
  TRAILER_PUP_SKIRT: "TRAILER_PUP_SKIRT",
  TRAILER_REFRIGERATED: "TRAILER_REFRIGERATED",
  TRAILER_SKIRTED: "TRAILER_SKIRTED",
  TRAILER_SOFT: "TRAILER_SOFT",
  TOM_ARRIVAL: "TOM_ARRIVAL",
  UNSELECT_ALL: "UNSELECT_ALL",
  UPDATED_AT: "UPDATED_AT",
  UPLOAD: "UPLOAD",
  UPLOAD_CSV: "UPLOAD_CSV",
  UPLOAD_NEW_FILE: "UPLOAD_NEW_FILE",
  URL_VALID: "URL_VALID",
  URL_INVALID: "URL_INVALID",
  VENDOR_RETURN_ARRIVAL: "VENDOR_RETURN_ARRIVAL",
  VIEW_CAMERA: "VIEW_CAMERA",
  VIEW_IN_MAP: "VIEW_IN_MAP",
  VIEW_STREAMS_AND_MASKS: "VIEW_STREAMS_AND_MASKS",
  WARNING: "WARNING",
  YARD: "YARD",
  YARD_ACTIONS: "YARD_ACTIONS",
  YARD_FEATURE: "YARD_FEATURE",
  YARD_FEATURES: "YARD_FEATURES",
  BUILDING_FEATURE: "BUILDING_FEATURE",
  BUILDING_FEATURES: "BUILDING_FEATURES",
  YARD_LABEL: "YARD_LABEL",
  YARD_MAP: "YARD_MAP",
  YARD_TYPE: "YARD_TYPE",
  YES: "YES",
};

export default keys;
