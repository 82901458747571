import PropTypes from "prop-types";

export const gateLane = PropTypes.shape({
  id: PropTypes.string.isRequired, // Unique id to identify a gateLane. Equals to GateLane$id defined in YDLR.
  laneIdentifier: PropTypes.string,
  gateId: PropTypes.string.isRequired, // Unique id to identify a gate area which could have multiple lanes. Equals to Gate$id defined in YDLR.
  gateIdentifier: PropTypes.string,
  label: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired,
  r4gMode: PropTypes.string.isRequired,
  kfbAccessPointId: PropTypes.string,
  intercomPhoneNumber: PropTypes.string,
});
