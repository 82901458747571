import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SaveChangesDialog from "component/Common/SaveChangesDialog";
import { extractGateLanes, hasTempId } from "helpers";
import {
  APIs,
  ENABLE_SKIP_VALIDATING_UNCHANGED_GATE_LANE,
  ENABLE_VALIDATE_GATE_CONFIGURATION,
} from "app-constants";
import {
  clearErrorSavingGates,
  saveGatesFailure,
  saveGatesRequest,
  saveGatesSuccess,
  callAPI,
} from "store";
import { gateLane } from "types";
import { isEmpty, isEqual } from "lodash";

/**
 * TODO: Change SaveLanesDialog to function instead of using component and write unit tests.
 */
class SaveLanesDialog extends React.PureComponent {
  saveGates = async () => {
    const { accountInfo, version, yardId, gateLanes, originalGates, featureFlags } = this.props;

    // Remove temp ids added to gates created directly with the UI
    // These ids are added because of https://sim.amazon.com/issues/Yard-3453
    // Removing these ids is needed because back-end needs new gates not to have ids
    // const gateId = gateLanes[0]?.gateId;
    const formattedGates = [];
    const uniqueGateIdentifierSet = [
      ...new Set(gateLanes.map((gateLane) => gateLane.gateIdentifier)),
    ];
    const uniqueGateIdAndIdentifierMap = new Map();
    uniqueGateIdentifierSet.forEach((gateIdentifier) => {
      gateLanes.some((gateLane) => {
        if (gateLane.gateIdentifier === gateIdentifier) {
          uniqueGateIdAndIdentifierMap.set(gateIdentifier, gateLane.gateId);
        }
      });
    });

    uniqueGateIdentifierSet.forEach((gateIdentifier) => {
      let formattedLanesForGate = gateLanes
        .filter((gateLane) => gateLane.gateIdentifier === gateIdentifier)
        .map((gateLane) => this.convertGateLaneToFormattedLane(gateLane));
      let gateId = uniqueGateIdAndIdentifierMap.get(gateIdentifier);
      let formattedGate = {
        id: hasTempId(gateId) ? null : gateId,
        gateIdentifier: gateIdentifier,
        lanes: formattedLanesForGate,
      };
      formattedGates.push(formattedGate);
    });

    this.props.saveGatesRequest(yardId);

    try {
      if (featureFlags[ENABLE_VALIDATE_GATE_CONFIGURATION]?.enabled) {
        let formattedGatesForBackendValidation = formattedGates;

        if (featureFlags[ENABLE_SKIP_VALIDATING_UNCHANGED_GATE_LANE]?.enabled) {
          // kfb API to validate kfb access point sometimes takes 2 seconds for each access point. To reduce occurrence of validation timeout,
          // only send updated gate lanes to backend for validation
          formattedGatesForBackendValidation = formattedGates
            .map((formattedGate) => {
              if (!formattedGate.id) {
                return formattedGate; // this is a new gate
              }

              return this.filterOutLanesUnchanged(formattedGate, originalGates);
            })
            .filter((formattedGate) => !isEmpty(formattedGate.lanes));
        }

        const validateGateConfigurationResponse = await callAPI(
          accountInfo,
          APIs.VALIDATE_GATE_CONFIGURATION,
          {
            yardId,
            gates: formattedGatesForBackendValidation,
          }
        );

        const serviceExceptions = validateGateConfigurationResponse.serviceExceptions;
        if (Array.isArray(serviceExceptions) && serviceExceptions.length !== 0) {
          let counter = 1;
          const concatenatedError = serviceExceptions
            .map(
              (serviceException) =>
                `Validation error ${counter++}: ${
                  serviceException?.exceptionInformation?.exceptionMessage
                }`
            )
            .join("; ");

          this.props.saveGatesFailure({ yardId, error: concatenatedError });
          return;
        }
      }

      const responseJson = await callAPI(accountInfo, APIs.SET_YARD_GATES, {
        version,
        yardId,
        gates: formattedGates,
      });

      this.props.saveGatesSuccess({ yardId, yardConfig: responseJson });
      this.props.dismiss();
    } catch (exception) {
      this.props.saveGatesFailure({ yardId, error: exception.message });
    }
  };

  convertGateLaneToFormattedLane = (gateLane) => {
    return {
      id: hasTempId(gateLane.id) ? null : gateLane.id,
      laneIdentifier: gateLane.laneIdentifier || "LANE1", // assign default laneIdentifier value
      label: gateLane.label,
      purpose: gateLane.purpose,
      kfbAccessPointId: gateLane.kfbAccessPointId,
      r4gMode: gateLane.r4gMode,
      intercomPhoneNumber: gateLane.intercomPhoneNumber,
    };
  };

  filterOutLanesUnchanged = (formattedGate, originalGates) => {
    const originalGateLanes = extractGateLanes(originalGates);

    const updatedLanes = formattedGate.lanes.filter((lane) =>
      this.isUpdatedLane(lane, originalGateLanes)
    );

    return {
      ...formattedGate,
      lanes: updatedLanes,
    };
  };

  isUpdatedLane = (currentLane, originalGateLanes) => {
    const originalLane = originalGateLanes.find((lane) => lane.id === currentLane.id);

    // return true if the lane is newly added or is changed
    return (
      !originalLane || !isEqual(currentLane, this.convertGateLaneToFormattedLane(originalLane))
    );
  };

  clearErrorAndDismiss = async () => {
    const { yardId } = this.props;
    this.props.clearErrorSavingGates(yardId);
    this.props.dismiss();
  };

  render() {
    const { yardConfigData, yardId } = this.props;
    const { savingGates, errorSavingGates } = yardConfigData[yardId];

    return (
      <SaveChangesDialog
        title={"SAVE_GATES"}
        saveChanges={this.saveGates}
        dismiss={this.clearErrorAndDismiss}
        savingChanges={savingGates}
        genericSavingErrorMessage={"SAVE_GATES_ERROR_MESSAGE"}
        serverErrorMessage={errorSavingGates}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo,
  yardConfigData: state.yardConfigData,
  featureFlags: state.featureFlags,
});

const mapDispatchToProps = (dispatch) => ({
  saveGatesRequest: (yardId) => {
    dispatch(saveGatesRequest(yardId));
  },
  saveGatesSuccess: (payload) => {
    dispatch(saveGatesSuccess(payload));
  },
  saveGatesFailure: (payload) => {
    dispatch(saveGatesFailure(payload));
  },
  clearErrorSavingGates: (yardId) => {
    dispatch(clearErrorSavingGates(yardId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveLanesDialog);

SaveLanesDialog.propTypes = {
  version: PropTypes.number.isRequired,
  yardId: PropTypes.string.isRequired,
  gateLanes: PropTypes.arrayOf(gateLane).isRequired,
  dismiss: PropTypes.func.isRequired,
};
