import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";
import LaneDialog from "componentsV2/Common/Dialogs/GateDialog/LaneDialog";
import SaveLanesDialog from "componentsV2/Common/Dialogs/GateDialog/SaveLanesDialog";

import {
  doListsContainSameElements,
  extractGateLanes,
  getGateIdentifierFromGate,
  getGateIdentifierNumberFromGate,
} from "helpers";
import { keys } from "i18n";
import { gateLane } from "types";
import { ENABLE_GAS_MASKING_TOOL } from "app-constants";
import { useNavigate } from "react-router-dom";

export default function TopActionBar({ yardId, gateLanes, setGateLanes, currentGate }) {
  const { permissions } = useSelector((state) => state.permissionsData);
  const featureFlags = useSelector((state) => state.featureFlags);
  const { gates: originalGates, version } = useSelector((state) => state.yardConfigData)[yardId];
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [shouldShowAddLaneDialog, setShouldShowAddLaneDialog] = useState(false);
  const [shouldShowSaveLanesDialog, setShouldShowSaveLanesDialog] = useState(false);

  const openAddLaneDialog = () => setShouldShowAddLaneDialog(true);
  const closeAddLaneDialog = () => setShouldShowAddLaneDialog(false);
  const openSaveLanesDialog = () => setShouldShowSaveLanesDialog(true);
  const closeSaveLanesDialog = () => setShouldShowSaveLanesDialog(false);

  const addNewLane = (gateLane) => {
    setGateLanes(gateLanes.concat(gateLane));
  };

  const canEdit = permissions.canEdit;
  const canConfigureCamera = permissions.canConfigureCamera;
  const currentGateIdentifier = getGateIdentifierFromGate(currentGate);
  const currentGateIdentifierNumber = getGateIdentifierNumberFromGate(currentGate);

  const areGateLanesChanged = () => {
    const originalGateLanesForCurrentGate = extractGateLanes(originalGates).filter(
      (gateLane) => gateLane.gateIdentifier === currentGateIdentifier
    );
    const gateLanesForCurrentGate = gateLanes.filter(
      (gateLane) => gateLane.gateIdentifier === currentGateIdentifier
    );
    return !doListsContainSameElements(originalGateLanesForCurrentGate, gateLanesForCurrentGate);
  };
  const areGateLanesUnChanged = !areGateLanesChanged();

  return (
    <>
      <Row data-testid="actionRow" alignmentHorizontal="justify">
        <Heading level={6} color="secondary">
          {t(keys.GUARD_SHACK)} {currentGateIdentifierNumber} - {currentGateIdentifier}
        </Heading>

        <Row>
          {featureFlags[ENABLE_GAS_MASKING_TOOL]?.enabled && (
            <Button
              data-testid="maskingToolButton"
              type="tertiary"
              disabled={!canConfigureCamera && !canEdit}
              onClick={() =>
                navigate(`/yards/${yardId}/config/${currentGateIdentifier}/cameraMasking`)
              }
            >
              {t(keys.CAMERA_MASKS)}
            </Button>
          )}

          <Button
            data-testid="addLaneButton"
            type="tertiary"
            disabled={!canEdit}
            onClick={openAddLaneDialog}
          >
            {t(keys.NEW_LANE)}
          </Button>
          <Button
            data-testid="saveLanesButton"
            type="tertiary"
            disabled={!canEdit || areGateLanesUnChanged}
            onClick={openSaveLanesDialog}
          >
            {t(keys.SAVE)}
          </Button>
        </Row>
      </Row>

      {shouldShowAddLaneDialog && (
        <LaneDialog
          gateLanes={gateLanes}
          currentGate={currentGate}
          add={addNewLane}
          edit={() => {}}
          dismiss={closeAddLaneDialog}
        />
      )}

      {shouldShowSaveLanesDialog && (
        <SaveLanesDialog
          version={version}
          yardId={yardId}
          gateLanes={gateLanes}
          originalGates={originalGates}
          dismiss={closeSaveLanesDialog}
        />
      )}
    </>
  );
}

TopActionBar.propTypes = {
  yardId: PropTypes.string.isRequired,
  gateLanes: PropTypes.arrayOf(gateLane).isRequired,
  setGateLanes: PropTypes.func.isRequired,
  currentGate: PropTypes.object.isRequired,
};
