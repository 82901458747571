import PropTypes from "prop-types";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { cloneDeep, isEqual } from "lodash";
import Box from "@amzn/meridian/box";
import { colorBlue500 } from "@amzn/meridian-tokens/base/color";
import Theme from "@amzn/meridian/theme";
import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import { keys } from "i18n";
import Button from "@amzn/meridian/button";
import { extractGateLanes, getGateIdentifierFromGate } from "helpers";
import LaneDialog from "componentsV2/Common/Dialogs/GateDialog/LaneDialog";
import { gateLane } from "types";

export default function ActionBar({
  yardId,
  gateLanes,
  currentGate,
  selectedIds,
  setGateLanes,
  setSelectedIds,
}) {
  const actionBarRowHeight = 40;

  const [shouldShowEditLaneDialog, setShouldShowEditLaneDialog] = useState(false);

  const { gates } = useSelector((state) => state.yardConfigData)[yardId];
  const originalGateLanes = extractGateLanes(gates);

  const { t } = useTranslation();

  // get selected ids only within current gate
  const currentGateIdentifier = getGateIdentifierFromGate(currentGate);
  const gateLaneIdsInCurrentGate = gateLanes
    .filter((gateLane) => gateLane.gateIdentifier === currentGateIdentifier)
    .map((gateLane) => gateLane.id);
  const selectedIdsInCurrentGate = selectedIds.filter((selectedId) =>
    gateLaneIdsInCurrentGate.includes(selectedId)
  );

  const selectedCount = selectedIdsInCurrentGate.length;
  const selectedGateLanes = gateLanes.filter(({ id }) => selectedIdsInCurrentGate.includes(id));

  const isSelectedGateLaneModified = () => {
    const selectedGateLane = selectedGateLanes[0];
    const originalGateLane = originalGateLanes.find(({ id }) => id === selectedGateLane.id);
    return originalGateLane && !isEqual(originalGateLane, selectedGateLane);
  };

  const restoreSelectedGateLane = () => {
    const selectedId = selectedIdsInCurrentGate[0];
    const originalGateLane = originalGateLanes.find(({ id }) => id === selectedId);
    setGateLanes(
      gateLanes.map((gateLane) =>
        gateLane.id === selectedId ? cloneDeep(originalGateLane) : gateLane
      )
    );
  };

  const editGateLane = (gateLane) =>
    setGateLanes(gateLanes.map((current) => (current.id === gateLane.id ? gateLane : current)));

  const removeSelectedGateLanes = () => {
    setGateLanes(gateLanes.filter(({ id }) => !selectedIds.includes(id)));
    setSelectedIds([]);
  };

  const openEditLaneDialog = () => setShouldShowEditLaneDialog(true);
  const closeEditLaneDialog = () => setShouldShowEditLaneDialog(false);

  return (
    <>
      <Box type="fill" backgroundColor={colorBlue500} spacingInset="small medium">
        <Theme tokens={blueDarkTokens}>
          <Row alignmentHorizontal="justify" height={actionBarRowHeight}>
            <Text data-testid={`selectedCountText-${selectedCount}`}>
              {`${selectedCount} ${t(keys.LANES_LOWER_CASE)} ${t(keys.SELECTED)}`}
            </Text>

            <Row spacing="small">
              {selectedCount === 1 && isSelectedGateLaneModified() && (
                <Button
                  data-testid="restoreLaneButton"
                  type="icon"
                  onClick={restoreSelectedGateLane}
                >
                  {t(keys.RESTORE)}
                </Button>
              )}

              {selectedCount === 1 && (
                <Button data-testid="editLaneButton" type="icon" onClick={openEditLaneDialog}>
                  {t(keys.EDIT)}
                </Button>
              )}

              {selectedCount !== 0 && (
                <Button
                  data-testid="removeLanesButton"
                  type="icon"
                  onClick={removeSelectedGateLanes}
                >
                  {t(keys.REMOVE)}
                </Button>
              )}
            </Row>
          </Row>
        </Theme>
      </Box>

      {shouldShowEditLaneDialog && (
        <LaneDialog
          gateLanes={gateLanes}
          gateLane={selectedGateLanes[0]}
          currentGate={currentGate}
          add={() => {}}
          edit={editGateLane}
          dismiss={closeEditLaneDialog}
        />
      )}
    </>
  );
}

ActionBar.propTypes = {
  yardId: PropTypes.string.isRequired,
  gateLanes: PropTypes.arrayOf(gateLane).isRequired,
  currentGate: PropTypes.object.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setGateLanes: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
};
